import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "phones/phoneOne@2x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          durationFadeIn="0"
          className={className}
          fluid={imageData}
        ></BackgroundImage>
      )
    }}
  />
)
const PhoneOne = styled(BackgroundSection)`
  color: #ffffff;
  width: 100%;
  height: 400px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 980px) {
    height: 360px;
  }
  @media only screen and (max-width: 824px) {
    height: 330px;
  }
  @media only screen and (max-width: 370px) {
    height: 313px;
  }
`

export default ({ children }) => <PhoneOne>{children}</PhoneOne>
