import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "phones/phoneTwo@2x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          durationFadeIn="0"
          className={className}
          fluid={imageData}
        >
        </BackgroundImage>
      )
    }}
  />
)
const PhoneTwo = styled(BackgroundSection)`
  color: #ffffff;
  width: 100%;
  height: 540px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;

  @media only screen and (max-width: 1063px) {
    height: 480px;
  }
  @media only screen and (max-width: 980px) {
    height: 398px;
  }
  @media only screen and (max-width: 824px) {
    height: 366px;
  }
  @media only screen and (max-width: 370px) {
    height: 313px;
  }
`

export default ({ children }) => <PhoneTwo>{children}</PhoneTwo>