import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 1140px;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  @media only screen and (max-width: 944px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 23px;
  }
`
const List = styled.ul`
  padding-left: 15px;
  padding-top: 30px;
  line-height: 30px;

  @media only screen and (max-width: 767px) {
    padding-top: 10px;
  }
`
const ListItem = styled.li`
  color: #d6ebe7;
  font-family: 'Libre Franklin', sans-serif;
  font-size: 18px;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  letter-spacing: normal;
  padding-bottom: 20px;

  @media only screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 24px;
  }
`

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "background/greenBG.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          className={className}
          fluid={imageData}
          backgroundColor={`#2f7563`}
        >
          <Wrapper className="wrapperTwo">
            <Col>
              <HeadingTwo>
                <span class="breakFullCol">Learn how food can nourish</span>
                <span class="breakFullCol"> your body and mind.</span>
              </HeadingTwo>
              <List>
                <ListItem>
                  Hundreds of guided practices to explore your behaviors,
                  emotions, beliefs, and past experiences with food
                </ListItem>
                <ListItem>
                  Simple intentions that you can practice during any part of
                  your day
                </ListItem>
                <ListItem>
                  Guided food journaling to reflect on your cumulative progress
                </ListItem>
              </List>
            </Col>
          </Wrapper>
        </BackgroundImage>
      )
    }}
  />
)
const GreenBanner = styled(BackgroundSection)`
  color: #ffffff;
  padding-top: 80px;
  padding-bottom: 60px;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;

  @media only screen and (max-width: 944px) {
    padding-top: 60px;
    padding-bottom: 0;
  }
  @media only screen and (max-width: 767px) {
    padding-top: 40px;
  }
`

export default ({ children }) => <GreenBanner>{children}</GreenBanner>
