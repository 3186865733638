import React from "react"
import { Link, graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import styled from "styled-components"
import { Row, Col } from "reactstrap"
import { documentToPlainTextString } from "@contentful/rich-text-plain-text-renderer"

const Wrapper = styled(Row)`
  width: 900px;
`
const BlogCol = styled(Col)`
  padding: 0;
  .blogWrapper:first-of-type {
    .homeBlogHeading {
      font-size: 36px;
      padding-top: 20px;
    }
    .profileImageWrapper {
      display: block;
    }
    .textCol {
      flex: 0 0 100%;
    }
    .col-md-8 {
      max-width: 100%;
      flex: 0 0 100%;
    }
    .col-md-4 {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .order-md-1 {
      order: 2;
    }
    .order-md-2 {
      order: 1;
    }
    .blogHeroImage:first-of-type {
      margin-left: 0;
      height: 500px;
    }
  }
  @media only screen and (max-width: 767px) {
    .blogWrapper:first-of-type {
      .homeBlogHeading {
        font-size: 26px;
      }
      .blogHeroImage:first-of-type {
        height: 410px;
      }
  }
  @media only screen and (max-width: 520px) {
    .blogWrapper:first-of-type {
      .blogHeroImage:first-of-type {
        height: 300px;
      }
  }
`
const Post = styled(Row)`
  margin-bottom: 60px;
  @media only screen and (max-width: 767px) {
    margin-bottom: 100px;
  }
`
export const HeroImage = styled(Img)`
  margin-left: 30px;
  @media only screen and (max-width: 767px) {
    margin-left: 0;
    height: 410px;
  }
  @media only screen and (max-width: 520px) {
    height: 300px;
  }
`
const StyledLink = styled(Link)`
  text-decoration: none;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
  }
`
const ExcerptWrapper = styled.div`
  max-width: 580px;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  font-size: 24px;
  color: #000000;
  font-family: "Libre Franklin";
  font-weight: bold;
  text-decoration: none;

  @media only screen and (max-width: 767px) {
    font-size: 26px;
    padding-top: 20px;
  }
`
const HeadingThree = styled.h4`
  ${props => props.theme.main.fonts.headingThree};

  font-size: 16px;
  font-weight: 600;
  margin: 0;
  @media only screen and (max-width: 824px) {
    font-size: 14px;
  }
  @media only screen and (max-width: 767px) {
    margin: 7px 0 0 0;
  }
`
const HeadingFour = styled.h5`
  ${props => props.theme.main.fonts.headingFour};
  color: #7f7a78;
  font-size: 13px;
  line-height: 1.8;
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
  font-size: 16px;
`
export const ProfileWrapper = styled.div`
  width: 100%;
  height: 52px;
`
export const ProfileImageWrapper = styled.div`
  width: 52px;
  height: 52px;
  float: left;
  margin-right: 10px;
  display: none;
  @media only screen and (max-width: 767px) {
    display: block;
  }
`
export const ProfileImage = styled(Img)`
  border-radius: 100px;
`
export const ProfileTextWrapper = styled.div`
  max-width: 650px;
  height: 52px;
  float: left;
`

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        allContentfulBlogPost(
          sort: { fields: publishDate, order: DESC }
          limit: 3
        ) {
          edges {
            node {
              slug
              title
              author {
                name
                photo {
                  fixed(width: 52, height: 52) {
                    ...GatsbyContentfulFixed
                  }
                }
              }
              publishDate(formatString: "MMMM DD, YYYY")
              heroImage {
                fluid(quality: 90, maxWidth: 4160, maxHeight: 2500) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
              }
              body {
                json
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Wrapper>
          <BlogCol>
            {data.allContentfulBlogPost.edges.map(({ node }) => (
              <Post className="blogWrapper">
                <Col
                  className="textCol"
                  xs={{ size: 12, order: 2 }}
                  md={{ size: 8, order: 1 }}
                >
                  <StyledLink to={node.slug}>
                    <HeadingTwo className="homeBlogHeading">
                      {node.title}
                    </HeadingTwo>
                    <ExcerptWrapper>
                      <ParagraphTwo>
                        {documentToPlainTextString(node.body.json).substring(
                          0,
                          155
                        )}
                        ...
                      </ParagraphTwo>
                    </ExcerptWrapper>
                    {node.author ? (
                      <ProfileWrapper>
                        <ProfileImageWrapper className="profileImageWrapper">
                          <ProfileImage fixed={node.author.photo.fixed} />{" "}
                        </ProfileImageWrapper>
                        <ProfileTextWrapper>
                          <HeadingThree>{node.author.name}</HeadingThree>
                          <HeadingFour>{node.publishDate}</HeadingFour>
                        </ProfileTextWrapper>
                      </ProfileWrapper>
                    ) : (
                      ""
                    )}
                  </StyledLink>
                </Col>
                <Col
                  className="imageCol"
                  xs={{ size: 12, order: 1 }}
                  md={{ size: 4, order: 2 }}
                >
                  <StyledLink to={node.slug}>
                    <HeroImage
                      className="blogHeroImage"
                      fluid={node.heroImage.fluid}
                    />
                  </StyledLink>
                </Col>
              </Post>
            ))}
          </BlogCol>
        </Wrapper>
      )
    }}
  />
)
const HomeBlog = styled(BackgroundSection)``
export default ({ children }) => <HomeBlog>{children}</HomeBlog>
