import React from "react"
import Layout from 'components/Layout/Layout';
import SEO from 'components/Layout/Seo';
import Home from 'components/Home/Home';

const IndexPage = () => (
  <Layout>
    <SEO title="Mindful Eating" />
    <Home />
  </Layout>
)

export default IndexPage
