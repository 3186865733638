import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"
import { Row, Col } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 1140px;
`
const HeroCol = styled(Col)`
  @media only screen and (max-width: 720px) {
    margin-top: -120px;
  }
  @media only screen and (max-width: 660px) {
    margin-top: -180px;
  }
  @media only screen and (max-width: 385px) {
    margin-top: -200px;
  }
`
const HeadingOne = styled.h1`
  ${props => props.theme.main.fonts.headingOne};
  @media only screen and (max-width: 1140px) {
    font-size: 42px;
  }
  @media only screen and (max-width: 944px) {
    font-size: 36px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 385px) {
    font-size: 26px;
  }
  @media only screen and (max-width: 320px) {
    font-size: 24px;
  }
`
const HeadingFour = styled.h5`
  ${props => props.theme.main.fonts.headingFour};
  @media only screen and (max-width: 824px) {
    font-size: 12px;
  }
`
const ParagraphOne = styled.p`
  ${props => props.theme.main.fonts.paragraphOne};
  @media only screen and (max-width: 944px) {
    font-size: 20px;
  }
  @media only screen and (max-width: 480px) {
    font-size: 18px;
  }
  @media only screen and (max-width: 385px) {
    font-size: 16px;
  }
`

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "hero/heroImage@2x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage Tag="section" className={className} fluid={imageData}>
          <Wrapper className="wrapperOne">
            <HeroCol>
              <HeadingOne>
                <span class="breakFullCol">Create a healthy</span>
                <span class="breakFullCol"> relationship with food</span>
              </HeadingOne>
              <ParagraphOne className="heroSubtext">
                and feel great about every meal.
              </ParagraphOne>
              <HeadingFour>Available Now</HeadingFour>
              <a
                href="https://apps.apple.com/us/app/savor/id1457112445"
                target="_blank"
              >
                <div class="appstoreWrapper"></div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=co.savorfood.android.app"
                target="_blank"
              >
                <div class="googleplayWrapper"></div>
              </a>
            </HeroCol>
          </Wrapper>
        </BackgroundImage>
      )
    }}
  />
)
const Hero = styled(BackgroundSection)`
  color: #ffffff;
  width: 100%;
  height: 800px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: -50px;
  bottom: -110px;

  @media only screen and (max-width: 1160px) {
    height: 680px;
  }
  @media only screen and (max-width: 1140px) {
    height: 580px;
  }
  @media only screen and (max-width: 944px) {
    height: 500px;
  }
  @media only screen and (max-width: 820px) {
    height: 450px;
  }
  @media only screen and (max-width: 768px) {
    height: 400px;
    margin-top: -30px;
    bottom: -90px;
  }
  @media only screen and (max-width: 720px) {
    height: 410px;
    margin-top: 70px;
  }
  @media only screen and (max-width: 680px) {
    height: 360px;
    margin-top: 70px;
    bottom: -90px;
  }
  @media only screen and (max-width: 660px) {
    margin-top: 120px;
  }
  @media only screen and (max-width: 510px) {
    height: 300px;
  }
  @media only screen and (max-width: 460px) {
    height: 250px;
    margin-top: 120px;
  }
  @media only screen and (max-width: 385px) {
    margin-top: 150px;
  }
`

export default ({ children }) => <Hero>{children}</Hero>
