import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Hero from "../Layout/Sections/Hero"
import GreenBanner from "../Layout/Sections/GreenBanner"
import PhoneOne from "../Layout/Sections/PhoneOne"
import PhoneTwo from "../Layout/Sections/PhoneTwo"
import PhoneThree from "../Layout/Sections/PhoneThree"
import HomeBlog from "../Layout/Sections/HomeBlog"
import Ebook from "../Layout/Sections/Ebook"
import { Container, Row, Col, Button, Form, FormGroup, Input } from "reactstrap"

const Wrapper = styled(Row)`
  max-width: 1140px;
`
const HeadingTwo = styled.h2`
  ${props => props.theme.main.fonts.headingTwo};
  @media only screen and (max-width: 1003px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 824px) {
    font-size: 23px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 23px;
  }
`
const HeadingThree = styled.h4`
  ${props => props.theme.main.fonts.headingThree};
  @media only screen and (max-width: 824px) {
    font-size: 14px;
  }
`
const HeadingFour = styled.h5`
  ${props => props.theme.main.fonts.headingFour};
  @media only screen and (max-width: 824px) {
    font-size: 12px;
  }
`
const HeadingPromo = styled.h3`
  ${props => props.theme.main.fonts.headingPromo};
  @media only screen and (max-width: 824px) {
    font-size: 54px;
  }
  @media only screen and (max-width: 767px) {
    font-size: 48px;
    padding: 30px 0 0 0;
  }
  @media only screen and (max-width: 263px) {
    font-size: 40px;
  }
`
const HeadingBlog = styled.h2`
  ${props => props.theme.main.fonts.headingBlog};
  @media only screen and (max-width: 980px) {
    font-size: 30px;
  }
`
const HeadingMailList = styled.h3`
  ${props => props.theme.main.fonts.headingMailList};
  color: ${props => props.focused};
  padding: 80px 0 0 0;
  @media only screen and (max-width: 1003px) {
    font-size: 30px;
  }
  @media only screen and (max-width: 824px) {
    font-size: 23px;
  }
  @media only screen and (max-width: 767px) {
    padding: 30px 0 0 0;
  }
`

const ParagraphOne = styled.p`
  ${props => props.theme.main.fonts.paragraphOne};
  @media only screen and (max-width: 824px) {
    font-size: 16px;
  }
`
const ParagraphTwo = styled.p`
  ${props => props.theme.main.fonts.paragraphTwo};
  @media only screen and (max-width: 824px) {
    font-size: 16px;
  }
  @media only screen and (max-width: 767px) {
    padding-bottom: 15px;
  }
`
export const query = graphql`
  query {
    desktop: file(relativePath: { eq: "icons/check/start.png" }) {
      childImageSharp {
        fluid(quality: 90, maxWidth: 15) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`

export default () => {
  const [focused, changeFocus] = useState(false)
  const [emailStatus, setEmail] = useState(null)
  const [enteredEmail, setEnteredEmail] = useState(null)
  const [enteredFname, setEnteredFname] = useState(null)
  const [enteredLname, setEnteredLname] = useState(null)
  const [formMessage, setMessage] = useState("Learn how to trust your body")

  async function submitForm() {
    try {
      if (enteredEmail && validateEmail(enteredEmail)) {
        const formSendResult = await addToMailchimp(enteredEmail, {
          FNAME: enteredFname,
          LNAME: enteredLname,
        })
        console.log(formSendResult)
        window.open(
          "../static/documents/Savor - It's time to ditch the diets - ebook.pdf"
        )
        setMessage("Thank You!")
        setEmail("success")
        changeFocus(true)
      }
    } catch (err) {
      console.log(err)
    }
  }

  function validateEmail(mail) {
    return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)
  }

  function handleEmailChange(event) {
    setEnteredEmail(event.target.value)
  }

  function handleFnameChange(event) {
    setEnteredFname(event.target.value)
  }

  function handleLnameChange(event) {
    setEnteredLname(event.target.value)
  }

  return (
    <Container fluid>
      <Hero />
      <GreenBanner />

      <Row>
        <Col>
          <Wrapper className="wrapperThree">
            <Col md="6">
              <HeadingTwo className="quoteOne">
                <span className="breakHalfCol">
                  <span className="pinkText">
                    Stop feeling frustrated, confused, or guilty.
                  </span>{" "}
                  End
                </span>
                <span className="breakHalfCol"> your battle with eating.</span>
              </HeadingTwo>
            </Col>
            <Col md="6">
              <div className="quoteTwoWrapper">
                <ParagraphTwo>
                  Build skills and habits that change how you feel about
                  yourself, your health, and your body.
                </ParagraphTwo>
              </div>
            </Col>
          </Wrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <Wrapper className="wrapperFour">
            <Col>
              <HeadingThree id="howItWorks">HOW IT WORKS</HeadingThree>
              <div className="underline"></div>
            </Col>
          </Wrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <Wrapper className="wrapperFive">
            <Col md="6">
              <PhoneOne />
            </Col>
            <Col md="6">
              <HeadingTwo className="headingWrapperFive">
                Notice why you eat certain foods and explore how you feel about
                food and your body
              </HeadingTwo>
              <ParagraphTwo>
                Use daily practices to find what’s right for you, rather than
                conforming to a diet.
              </ParagraphTwo>
            </Col>
          </Wrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <Wrapper className="wrapperSix">
            <Col xs={{ size: 12, order: 2 }} md={{ size: 6, order: 1 }}>
              <HeadingTwo className="headingWrapperSix">
                <span className="breakHalfCol">Reflect and grow with </span>
                <span className="breakHalfCol">guided food journaling</span>
              </HeadingTwo>
              <ParagraphTwo>
                A safe and comfortable environment to explore your past and
                present emotions around food. No calorie counting or obsessive
                tracking.
              </ParagraphTwo>
            </Col>
            <Col xs={{ size: 12, order: 1 }} md={{ size: 6, order: 2 }}>
              <PhoneTwo />
            </Col>
          </Wrapper>
        </Col>
      </Row>

      <Row className="patternWrapper">
        <Col>
          <Wrapper className="wrapperSeven">
            <Col className="wrapperSevenCol" md="6">
              <PhoneThree />
            </Col>
            <Col className="wrapperSevenCol" md="6">
              <HeadingPromo>
                <span className="breakPromo">Try Savor </span>
                <span className="breakPromo">for free</span>
              </HeadingPromo>
              <ParagraphOne>
                Download the Savor app from the App Store or the Google Play
                store. Start creating a healthy relationship with food today.
              </ParagraphOne>
              <HeadingFour>Download Now</HeadingFour>
              <a
                href="https://apps.apple.com/us/app/savor/id1457112445"
                target="_blank"
              >
                <div className="appstoreWrapper"></div>
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=co.savorfood.android.app"
                target="_blank"
              >
                <div className="googleplayWrapper"></div>
              </a>
            </Col>
          </Wrapper>
          <div className="bottomBorder"></div>
          <div className="patternOnePromo"></div>
          <div className="patternTwoPromo"></div>
          <div className="patternThreePromo"></div>
        </Col>
      </Row>

      <Row>
        <Col>
          <Wrapper className="wrapperEight">
            <Col>
              <HeadingBlog>Savor Blog</HeadingBlog>
              <Link className="blogLink" to="/blog/">
                View All
              </Link>
            </Col>
          </Wrapper>
          <Wrapper className="wrapperNine">
            <HomeBlog />
          </Wrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <Wrapper className="wrapperTen">
            <Col md="5">
              <Ebook />
            </Col>
            <Col md="7">
              <div id="subscribe" className="formWrapper">
                <div class="form-heading-wrapper">
                  <HeadingMailList focused={focused}>
                    {formMessage}
                  </HeadingMailList>
                </div>
                <ParagraphTwo>
                  Subscribe to our newsletter and download your free e-book.
                </ParagraphTwo>
                <Form inline className="form">
                  <div class="firstname-wrapper">
                    <FormGroup className="firstname-input">
                      <Input
                        type="text"
                        name="fname"
                        id="firstnameInput"
                        placeholder="First Name"
                        onFocus={() => changeFocus("#489786")}
                        onBlur={() => changeFocus("")}
                        onChange={handleFnameChange}
                      />
                    </FormGroup>
                  </div>
                  <div class="lastname-wrapper">
                    <FormGroup className="lastname-input">
                      <Input
                        type="text"
                        name="lname"
                        id="lastnameInput"
                        placeholder="Last Name"
                        onFocus={() => changeFocus("#489786")}
                        onBlur={() => changeFocus("")}
                        onChange={handleLnameChange}
                      />
                    </FormGroup>
                  </div>
                  <div class="email-wrapper">
                    <FormGroup className="email-input">
                      <Input
                        type="email"
                        name="email"
                        id="emailInput"
                        placeholder="Email Address"
                        onFocus={() => changeFocus("#489786")}
                        onBlur={() => changeFocus("")}
                        onChange={handleEmailChange}
                        disabled={emailStatus === "success"}
                      />
                    </FormGroup>
                    <Button onClick={submitForm}>
                      {emailStatus === "success" ? "Sent" : "Subscribe"}
                    </Button>
                  </div>
                </Form>
              </div>
            </Col>
          </Wrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <Wrapper className="wrapperEleven">
            <Col>
              <HeadingThree id="about">ABOUT US</HeadingThree>
              <div className="underline"></div>
            </Col>
          </Wrapper>
        </Col>
      </Row>

      <Row>
        <Col>
          <Wrapper className="wrapperTwelve">
            <div class="about-wrapper">
              <div class="about-colOne">
                <div class="patternOneAbout"></div>
                <div class="about-photoOne"></div>
              </div>
              <div class="about-colTwo">
                <ParagraphTwo>
                  Nick is the founder of Savor and the Director of Product. With a background in creating digital experiences for all, Nick is passionate about helping people create a healthy relationship with food.<span> </span>
                  <Link className="aboutLink" to="/founderstory/">
                    Read his story here.
                  </Link>
                </ParagraphTwo>
              </div>
            </div>
            <div class="about-wrapper">
              <div class="about-colOne">
                <div class="patternTwoAbout"></div>
                <div class="about-photoTwo"></div>
              </div>
              <div class="about-colTwo">
                <ParagraphTwo>
                  Kiara Tchir is a Registered Holistic Nutritionist and the
                  Director of Education at Savor. An empathetic writer and
                  student of self-development, Kiara is the calm, non-judgmental
                  voice behind our content.
                </ParagraphTwo>
              </div>
            </div>
            <div class="about-wrapper">
              <div class="about-colOne">
                <div class="patternThreeAbout"></div>
                <div class="about-photoThree"></div>
              </div>
              <div class="about-colTwo">
                <ParagraphTwo>
                  Cheri Hanson is a writer, journalist, and the Director of Content Strategy at Savor. She believes that stories have the power to change how we think, act, and relate to both ourselves and others.
                </ParagraphTwo>
              </div>
            </div>
          </Wrapper>
        </Col>
      </Row>

      <Row className="wrapperThirteen">
        <Col>
          <p className="share-text">
            We would love to hear your stories and struggles with food and body image.
          </p>
          <p className="share-text">
            Note: We never publish, share, or distribute anything without your explicit permission.
          </p>
          <a href="mailto:nick@savor.co">
            <Button className="share-button">Share</Button>
          </a>
        </Col>
      </Row>
    </Container>
  )
}
