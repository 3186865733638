import React from "react"
import { graphql, StaticQuery } from "gatsby"
import styled from "styled-components"
import BackgroundImage from "gatsby-background-image"

const BackgroundSection = ({ className }) => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "ebook/ebook-cover@2x.png" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 4160) {
              ...GatsbyImageSharpFluid_withWebp_noBase64
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage
          Tag="section"
          durationFadeIn="0"
          className={className}
          fluid={imageData}
        ></BackgroundImage>
      )
    }}
  />
)
const Ebook = styled(BackgroundSection)`
  color: #ffffff;
  width: 360px;
  height: 478px;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: contain;
  webkit-box-shadow: 0px 4px 25px 5px rgba(232,232,232,0.7);
  -moz-box-shadow: 0px 4px 25px 5px rgba(232,232,232,0.7);
  box-shadow: 0px 4px 25px 5px rgba(232,232,232,0.7);

  @media only screen and (max-width: 900px) {
    width: 330px;
    height: 439px;
  }
  @media only screen and (max-width: 824px) {
    width: 315px;
    height: 419px;
  }
  @media only screen and (max-width: 767px) {
    margin: 0 auto;
  }
  @media only screen and (max-width: 362px) {
    width: 280px;
    height: 372px;
  }
`

export default ({ children }) => <Ebook>{children}</Ebook>